<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {}
</script>
<style lang="scss">
@import '@/style/variable.scss';
 #app {
  max-width: 539px;
  height: 100%;
  margin: 0 auto;
  color: $black;
 }
</style>
